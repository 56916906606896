.slider-content{
    display:grid;
}

.img-slider{
    width: 600px;
}

.focus-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.focus-area.active {
  opacity: 0.5;
}
.fullscreen-black{
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999;
}

.row-space {
  padding-right: 15px;
  margin: 5px;
}

.massive-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 500px;
  justify-content: center;
	align-items: center;
}

.massive-item__dragger {
  height: 350px;
  padding-bottom: 80px;
  overflow-y: auto;

}

.massive-item__control {
  max-width: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}


.channel-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.channel-button .ant-btn svg {
  margin: 0 auto;
}

.item-plus-notification {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-notification {
  margin: 5px;
  padding: 10px;
  border: dashed #D9D9D9;
}

.item-notification Input {
  max-width: 90%;
  width: 100%;
  border: 0;
}

.item-footer-notification {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}

.container-lamp {
  margin: 24px 0px;
  border-right: 1px solid rgba(0, 0, 0, .2);
  overflow-y: auto;
  max-height: 630px;
}

.item-lamp {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
  cursor: pointer;
}

.item-lamp-active {
  background-color: #188fff2b;
  border-right: 3px solid #1890FF;
}

.item-measurements {
  padding: 34px;
  margin: 10px;
  min-width: 150px;
  border: 1px solid #000386;
  border-radius: 10px;
}

.item-measurements p:nth-child(2) {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 0;
}

.item-measurements p:nth-child(3) {
  font-size:24px;
  right: 5px;
  color: #39C337;
}

.item-measurements div:nth-child(4) {
  text-align: center;
}

.container__dimerization {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  height: 500px;
}

.container__dimerization-slider {
  margin: 15px;
}

.single__header {
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
  background-color: #FAFAFA;
  width: 100%;
}

.single__header-img {
  width: 100px;
  height: 50px;
}

.single__footer {
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #000386;
}

.single__footer div {
  margin-top: 10px;
}

.single__footer .white {
  color: #fff;
}

.single__footer .icons {
  color: #fff;
  font-size: 18px;
}

.single__content {
 display: flex;
 flex-wrap: nowrap;
 justify-content: center;
 align-content: center;
 margin: 0 25px 0 25px;
}

.single__content .single__content__subcontainer {
  width: 1024px;
  justify-self: center;
  align-self: center;
}

.single__content__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.single__content__header-h2 {
  /* position: absolute;
  top: 0; */
  text-align: center;
  font-size: 36px;
  font-weight: 700;
}

.single__content__circle {
  width: 54rem;
  height: 34rem;
  background-color: blue;
  border-radius: 50% 50% 0 0;
  /* position: absolute;  */
 
}

.single__content__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.single__content__list-h2 {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: #000;
  font-weight: 700;
  font-size: 24px;
  
}

.single__content__list-item {
  width: calc(40% - 16px);
  margin: 10px;
  text-align: left;
}

.single__content__images {
  margin-bottom: 100px;
}

.layout-register {
  width: 100vw;
  height: 100vh;
}

.container-register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 700px;
}

.card-register {
  width: 600px;
  height: 600px;
}

.title-register {
  margin-bottom: 0;
}

.scan__container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.marker-pup{
  width: 450px;
}

.space-marker{
  padding-left: 5px;
}


.code-container {
  display: flex;
  flex-direction: column;
}


.center-item {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  column-gap: 6rem;
  padding-top: 5px;
}

.tem-text {
  font: 1.3em sans-serif;
}

.item-pointer {
  cursor: pointer;
}

.item-col-lamp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hover-off .ant-table-row:hover {
  background-color: transparent;
}

.hover-off .ant-table-cell-row-hover {
  background-color: inherit !important;  
}